@import '../../constants/style';

.column-overview__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.column-overview__header .column-name .input, .column-overview__header .column-name {
  color: $color-white;
}

.column-overview__stack {
  margin-top: 32px;
}