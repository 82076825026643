@import '../../constants/style';
@import '../../../node_modules/react-dd-menu/dist/react-dd-menu';

$icon__width: 24px;
$icon__height: $icon__width;

.dd-menu {
  position: relative;

  display: flex;
  margin-left: $default-margin;
}

.dd-menu.dd-menu-right {
  align-self: center;
}

.user-menu__toggle-button {
  position: relative;
  cursor: pointer;

  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  background: none;

  width: $icon__width;
  height: $icon__height;
}

.user-menu__toggle-icon {
  position: absolute;
  overflow: hidden;

  top: 0;
  left: 0;

  width: $icon__width;
  height: $icon__height;
}

.user-menu__toogle-icon-hover-circle {
  opacity: 0;
  transition: opacity .15s ease-in-out;
}

.user-menu__toggle-button:focus > .user-menu__toogle-icon-hover-circle,
.user-menu__toggle-button:hover > .user-menu__toogle-icon-hover-circle {
  opacity: 1;
}

.dd-menu.dd-menu-right .dd-menu-items {
  right: 0;
  top: calc(100% - 20px);

  z-index: $base-z-index + $base-z-index-step * 5;
}

.dd-menu .dd-menu-items.dd-items-upwards {
  bottom: 100%;
  top: auto;
}

.dd-menu .dd-menu-items li {
  margin: 0;
  display: block;
  background-color: transparent !important;
}

.dd-menu .dd-menu-items ul {
  box-shadow: 0 24px 32px 0 rgba(39,50,47,0.15);
}

.user_menu__button-icon {
  align-self: center;

  display: block;

  width: 20px;
  height: 20px;
  min-width: $default-margin !important;
  max-width: 20px;
  line-height: 32px;

  margin-right: $default-margin;
  padding: 0 !important;
}

.user_menu__button-icon > svg {
  margin-top: 6px;
  width: 20px;
  height: 20px;
}

.user_menu__button-icon--inverted * {
  fill: $color-white;
}

.dd-menu .dd-menu-items li.user-menu__board-name {
  background-color: $color-dark-gray !important;
  padding: 1em 1.5em;
  display: flex;
}

.user-menu__input {
  text-align: center !important;
  display: block;

  white-space: pre;
  text-decoration: none;
  font-size: inherit;
  color: $color-white;

  min-width: 100px !important;
  max-width: 200px;
}

.user-menu__input:focus {
  background-color: $theme-color-background;
}

.user-menu__boardname {
  text-align: left !important;
  min-width: 160px;
  display: block;
  border: none;
  color: $color-white;
  line-height: 30px;
}

@media #{$tablet} {
  .dd-menu {
    margin-left: $default-margin;
  }
}

.user-menu__logout::before {
  content: ' ';
  display: block;
  height: 1px;
  background-color: $color-alternative-gray;
  margin: 0 1.5em;
}

.dd-menu-items li.user_menu__li--hidden-mobile {
  display: none;
}

@media #{$tablet} {
  .dd-menu-items li.user_menu__li--hidden-mobile {
    display: inherit;
  }
}

.user-menu__with-buttons {
  display: flex !important;
}

.user-menu__with-buttons__text {
  align-self: center;
  flex: 0 1 !important;
  margin-right: 16px;
}

.user-menu__with-buttons__button {
  align-self: center;
  height: 32px;
  min-width: 42px;
  background: transparent;

  padding-left: 8px;
  padding-right: 8px;

  border: 2px solid black;
  border-radius: 12px;

  font-size: 12px;
  font-weight: bold;
  color: $color-dark-gray;
  letter-spacing: 1px;
  line-height: 24px;

  transition: background .2s ease-in-out;
  outline: none;
  cursor: pointer;
}

.user-menu__with-buttons__button:focus,
.user-menu__with-buttons__button:hover {
  background: $color-green;
}

.user-menu__with-buttons__button + .user-menu__with-buttons__button {
  margin-left: 8px;
}
