@import '../../constants/style';

.modal__content-wrapper {
  overflow: auto;
  //margin-top: $large-margin;

  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100px;

  width: 100%;
  height: 100%;
}

.modal__content {
  position: relative;
  background: $color-white;
  min-width: 200px;
  max-width: 860px;
  min-height: 100px;
  padding: $large-padding * 1.5;
  box-shadow: 0px 32px 64px 0 rgba(0, 0, 0, 0.75);
  margin: 64px;
  border-radius: 6px;
}

.modal__close-button {
  position: absolute;
  top: $large-margin;
  right: $large-margin;

  background: none;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;

  cursor: pointer;
}

.modal__close-button * {
  transition: fill .15s ease-in-out;
  fill: $color-dark-gray;
}

.modal__close-button:focus *,
.modal__close-button:hover * {
  fill: $color-mint;
}

.modal__headline {
  align-self: flex-start;
  margin-top: $default-margin;
}

.modal__action-area {
  display: flex;
  justify-content: flex-end;

  margin-top: $large-margin
}

.modal__ack-button {
  align-self: flex-end;

  border: 0;
  background: none;
  outline: none;

  font-size: $theme-font-size;
  font-weight: bold;
  color: $color-mint;

  padding: $default-padding;
  transition: background .2s ease-in-out, color .15s ease-in-out;
}

.modal__ack-button:focus,
.modal__ack-button:hover {
  color: $color-white;
  background: $color-mint;
}