@import '../../../constants/style';

.users-modal {
  display: flex;
  flex-direction: column;
}

.users-modal__users-list {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-self: center;
  min-height: 100px;
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
}

.users-modal__users-list li {
  display: flex;
  box-sizing: border-box;
  height: 50px;
}

.users-modal__users-list li > div{
  display: flex;
  box-sizing: border-box;
  align-self: center;
  margin-right: 16px;
}