@import '../../constants/style';

.welcome-area {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  overflow: auto;

  width: 100vw;
  min-height: 100vh;
}

.welcome-area__headline {
  background: url('../../assets/logo-scrumlr-beta.svg') no-repeat;
  background-position-x: center;

  height: 74px;
  width: 164px;
  text-indent: -10000px;

  margin-bottom: $large-margin*2;
}

.welcome-area__promotion-wrapper {
  background: url('../../assets/visual-chair-scrumlr.svg') no-repeat $large-margin 72px, linear-gradient(to bottom, $color-mint, $color-darkest-mint);
  background-size: auto 100%;

  flex: 2 1 66%;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome-area__promotion {
  font-size: initial;
  text-align: left;

  min-width: 320px;
  max-width: 640px;

  align-self: center;

  padding: $large-padding;
}

.welcome-area__promotion-text > p {
  color: $color-white;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 24px;
}

.welcome-area__promotion-text > h2 {
  color: $color-white;
  font-weight: 900;
  font-size: 6vw;
  letter-spacing: 0;
  line-height: 8vw;
}

.welcome-area__action-area {
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex: 1 1 33%;

  background-color: #fff;
  padding: $large-padding;
}

.welcome-area__action-area-main {
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex: 1 1 100%;
}

.welcome-area__action-area-footer {
  text-align: center;

  font-size: 13.6px;
  color: $color-stone-gray;
}

.welcome-area__action-area-footer p {
  margin-bottom: .25em;
}

.welcome-area__action-area-footer a {
  font-weight: bold;
  color: currentColor;
}

.welcome-area__action-area-logo {
  vertical-align: middle;
}

.welcome-area__policies ul {
  list-style: none;
  padding: 0;
  margin: .75em 0 0;
}

.welcome-area__policies ul > li {
  display: inline;
  white-space: nowrap;
}

.welcome-area__policies ul > li:before {
  content: " | ";
}

.welcome-area__policies ul > li:first-child:before {
  content: '';
}

@media #{$tablet} {
  .welcome-area__promotion-text > h2 {
    font-size: 36px;
    letter-spacing: 0;
    line-height: 48px;
  }

  .welcome-area {
    flex-wrap: nowrap;
  }

  .welcome-area__action-area {
    min-width: 300px;
    max-width: 420px;

    padding: $large-padding*2;
  }

  .welcome-area__action-area {
    z-index:1;
    box-shadow: 0 0 $base-unit*16 0 transparentize($color-dark, .45);
  }
}


