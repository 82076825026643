@import '../../constants/style';

.input__wrapper {
  display: flex;
  flex-direction: column;

  flex: 1;

  padding-right: $default-margin;

  min-width: 256px;
}

.input__label {
  text-transform: uppercase;
  font-size: $theme-font-size;
  font-weight: 500;
  margin-bottom: $base-unit * 2;
}

.input__description {
  margin-top: $base-unit * 1.5;
  font-size: $theme-font-size - $base-unit / 2;
}

.input__description--error {
  color: red;
}

.input {
  flex: 1;
  background: none;

  box-sizing: border-box;

  border: none;
  border-radius: 0;
  outline: none;

  font-family: 'Heebo', sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 24px;

  color: currentColor;
}

.input--underlined > .input {
  border-bottom: 1px solid $color-middle-gray;
}

.input--underlined.input--error > .input {
  border-bottom-color: red;
}

.input--underlined > .input:focus {
  border-bottom: none;
}

.input--underlined > .input__underline {
  margin-top: -1px;
  margin-bottom: 0px;
}

.input--underlined > .input:focus + .input__underline {
  margin-top: -1px;
  margin-bottom: 0;
}

.input--error > .input__underline {
  background-color: red !important;
}

.input__underline {
  align-self: center;

  background-color: currentColor;
  height: 1px;
  transition: width 0.25s linear;
  width: 0;

  margin-top: 1px;
}

.input__focus-theme-mint > .input:hover::placeholder,
.input__focus-theme-mint > .input:focus::placeholder {
  color: $color-mint;
}

.input__focus-theme-mint > .input__underline {
  background-color: $color-mint;
}

.input:focus + .input__underline,
.input:hover + .input__underline {
  width: 100%;
}

.input:focus + .input__underline {
  margin-top: 0px;
  height: 2px;
}

.input::placeholder {
  font-weight: bold;
  color: $color-alternative-gray;
  text-transform: uppercase;

  transition: color .25s linear;
}

.input--inverted-placeholder::placeholder {
  color: $color-white;
  opacity: 0.8;
}
