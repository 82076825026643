@import "../../constants/style";

.access-mode-selection {
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 100%;
  min-height: 110px;
  display: none;
}

.access-mode-selection__radio {
  display: flex;
  flex: 1 1 100%;
}

.access-mode-selection__radio + .access-mode-selection__radio {
  margin-left: $default-margin;
}

#access-mode-selection__private,
#access-mode-selection__public {
  display: none;
}

.access-mode-selection__label {
  box-shadow: 0 $base-unit $base-unit * 2 0 transparentize($color-dark-gray, 0.85);
  padding: $default-padding;
  transform: scale(0.9);
  transition: transform 0.2s ease-in-out;
  color: $color-middle-gray;
  display: grid;
  border-radius: 4px;
}

.access-mode-selection__label:hover {
  cursor: pointer;
  transform: scale(1);
  box-shadow: 0 $base-unit * 2 $base-unit * 4 0 transparentize($color-dark-gray, 0.85);
}

#access-mode-selection__public:checked + .access-mode-selection__label,
#access-mode-selection__private:checked + .access-mode-selection__label {
  background-color: $color-darkest-mint;
  transform: scale(1.05);
  box-shadow: 0 $base-unit * 2 $base-unit * 4 0 transparentize($color-dark-gray, 0.85);
  color: $color-white;
  transition: color 0.2s ease-in-out;

  .access-mode-selection__icon * {
    stroke: $color-white;
    transition: stroke 0.2s ease-in-out;
  }
}

.access-mode-selection__icon {
  margin: $default-margin auto auto;
}

.access-mode-selection__icon * {
  stroke: $color-middle-gray;
}

.access-mode-selection__title {
  margin-bottom: 0;
  font-size: 16px;
  text-align: center;
}

.access-mode-selection__description {
  font-size: 14px;
  margin-bottom: 0;
  text-align: center;
}
