@import '../../../constants/style';

.column-header {
  margin: $large-margin $default-margin $default-margin;
}

@media #{$desktop} {
  .column-header {
    margin-left: $large-margin;
    margin-right: $large-margin;
    margin-top: $large-margin;
  }

  .column-header__navigation {
    display: none;
  }
}

.column-header__hr {
  background: $color-dark-gray;
  height: 4px;
  border: none;
}

.column-header__title-wrapper {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.column-header__overview-button {
  background: none;
  border: none;
  outline: none;

  margin: 0;
  padding: 0;
}

.column-header__overview-icon-circle {
  @include animate(fill);
}

.column-header__overview-button:focus .column-header__overview-icon-circle,
.column-header__overview-button:hover .column-header__overview-icon-circle {
  fill: $color-dark;
}

.column-header__overview-button:active .column-header__overview-icon-circle {
  fill: $color-dark-mint;
}

.column-header__overview-icon-square {
  @include animate(fill);
}

.column-header__overview-button:focus .column-header__overview-icon-square,
.column-header__overview-button:hover .column-header__overview-icon-square {
  fill: $color-white;
}

