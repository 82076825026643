.membership-request-modal__h1 {
  //margin-left: 72px;
  margin-top: 0;
  margin-bottom: 1em;
  text-align: center;
  font-size: 28px;
}

.membership-request-modal__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
}

.membership-request-modal__request-message {
  display: flex;
  align-items: flex-start;

  margin-bottom: 24px;
  font-size: 14px;

  //border: 4px solid #0057ff;
  //padding: 24px;
}

.membership-request-modal__member-photo {
  border-radius: 100%;
  width: 48px;
  height: 48px;
  margin-right: 24px;
}

.membership-request-modal__prompt {
  max-width: 300px;
  margin: 0;
}

.membership-request-modal__action-area {
  display: flex;
  justify-content: center;
  //margin-left: 29px;
}

.membership-request-modal__action-area > * + * {
  margin-left: 12px;
}