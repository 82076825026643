@import '../../constants/style';

.add-card {
    flex: 1;
    display: flex;
}

.add-card__button {
    cursor: pointer;
    box-sizing: border-box;
    align-self: center;

    width: 32px;
    height: 32px;
    line-height: 28px;

    font-weight: bold;

    border: none;
    color: currentColor;
    border-radius: 50%;
    outline: none;
    background: none;

    margin: 0;
    padding: 0;
}

.add-card__button > span {
    display: block;
    width: auto;
    height: 24px;
    line-height: 32px;
}

.add-card__plus-circle .add-card__plus-circle-icon-circle,
.add-card__plus-circle .add-card__plus-circle-icon-plus-line {
    stroke: currentColor;
    @include animate(stroke);
    @include animate(fill);
}


.add-card.add-card--theme-light .add-card__button:hover .add-card__plus-circle-icon-circle,
.add-card.add-card--theme-light .add-card__button:focus .add-card__plus-circle-icon-circle,
.add-card.add-card--theme-light .add-card__button:active .add-card__plus-circle-icon-circle {
    fill: $color-dark-gray;
    stroke: $color-dark-gray;
}

.add-card.add-card--theme-light .add-card__button:hover .add-card__plus-circle-icon-plus-line,
.add-card.add-card--theme-light .add-card__button:focus .add-card__plus-circle-icon-plus-line,
.add-card.add-card--theme-light .add-card__button:active .add-card__plus-circle-icon-plus-line {
    stroke: $color-white;
}

.add-card.add-card--theme-dark .add-card__button:hover .add-card__plus-circle-icon-circle,
.add-card.add-card--theme-dark .add-card__button:focus .add-card__plus-circle-icon-circle,
.add-card.add-card--theme-dark .add-card__button:active .add-card__plus-circle-icon-circle {
    fill: $color-white;
    stroke: $color-white;
}

.add-card.add-card--theme-dark .add-card__button:hover .add-card__plus-circle-icon-plus-line,
.add-card.add-card--theme-dark .add-card__button:focus .add-card__plus-circle-icon-plus-line,
.add-card.add-card--theme-dark .add-card__button:active .add-card__plus-circle-icon-plus-line {
    stroke: $color-dark-gray;
}

.add-card.add-card--theme-mint .add-card__button:hover .add-card__plus-circle-icon-circle,
.add-card.add-card--theme-mint .add-card__button:focus .add-card__plus-circle-icon-circle,
.add-card.add-card--theme-mint .add-card__button:active .add-card__plus-circle-icon-circle {
    fill: $color-dark-gray;
    stroke: $color-white;
}


