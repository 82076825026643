// base constants
$base-unit: 4px;
$base-z-index: 100;
$base-z-index-step: 10;

// color constants
$color-dark: #333333;
$color-white: #fcfcfc;
$color-green: #b91131;

// new font colors
$color-white: #ffffff;
$color-dark-gray: #27322f;
$color-middle-gray: #5f746e;
$color-alternative-gray: #c8cbca;
$color-mint: #b91131;
$color-dark-mint: #830c22;
$color-darkest-mint: #5e0918;
$color-gray: #e4e6e5;
$color-light-gray: #f0f1f1;
$color-lightest-gray: #f7f7f7;
$color-stone-gray: #a4a8a7;
$color-orange: #ee5800;

// theme colors
$theme-color-background: $color-white;
$theme-color-font: $color-dark;
$theme-color-primary: $color-green;
$theme-color-secondary: $color-white;
$theme-color-disabled: $color-gray;

// font options
$theme-font-family: "Heebo", sans-serif;
$theme-font-size: $base-unit * 4;
$theme-line-height: $base-unit * 6;

// responsive
$tablet: "screen and (min-width : 768px)";
$desktop: "screen and (min-width : 1280px)";

// margins & paddings
$small-margin: $base-unit * 2;
$default-margin: $base-unit * 4;
$large-margin: 2 * $default-margin;
$default-padding: $default-margin;
$large-padding: $large-margin;

// dimensions
$theme-icon-width: $base-unit * 8;
$theme-icon-height: $base-unit * 8;

// mixins
@mixin animate($property, $duration: 0.3s) {
  transition: $property $duration ease-in-out;
}
