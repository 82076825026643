@import '../../../constants/style';
@import '../Card';

$icon__width: $theme-font-size * 2;
$card-details-z-index: $card-z-index + $base-z-index-step;

.card-details__backdrop {
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.75);

  z-index: $card-details-z-index;
}

.card_details__card {
  @extend .card;
  overflow: visible;
  min-width: calc(240px + 10vw);
  width: calc(100vw - 32px);
  max-width: 380px;

  box-shadow: 3px 3px 3px 3px rgba(0,0,0,0.2);
}

@media #{$desktop} {
  .card_details__card {
    max-width: 440px;
  }
}

.card-details__card-text {
  @extend .card__content;
  overflow: inherit;
  height: auto;
  min-height: 4.5em;
  cursor: text;

  padding: 0;
  margin: 0;
  transition: outline .3s ease-in-out;
  outline: solid 2px transparent;
  outline-offset: $base-unit * 1.5;
}

.card-details__card-text:focus {
  outline: solid 2px $color-alternative-gray;
}

.card-details__card-text > *:first-child {
  margin-top: 0;
  padding-top: 0;
}

.card-details__content-text--edit-mode {
  box-sizing: border-box;

  height: 9.5em;
  width: 100%;
  padding: 1em;
  font-size: $theme-font-size;
  line-height: 1.5;
}

.card-details__options {
  display: none;
  list-style-type: none;
  margin: 0;
  padding: 0;

  position: absolute;
  top: 0;
  right: -44px - $default-margin;
}

@media #{$tablet} {
  .card-details__options {
    display: block;
  }
}

.card-details__option {
  box-sizing: border-box;
  display: block;
}

.card-details__option + .card-details__option {
  margin-top: $small-margin;
}

.card-details__stack {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.card-details__stack-item {
  margin-top: 1em;
}

.card-details__options-icon {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
}

.card-details__options-icon svg {
  fill: #fff;
  width: 20px;
  height: 20px;
  align-self: center;
}
