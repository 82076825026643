@import '../../../constants/style';

.column-name__header {
  margin: 0;
  line-height: 54px;
  display: inline-flex;
  overflow-x: hidden;
}

.column-name .input, .column-name {
  font-family: Heebo, sans-serif;
  font-weight: bold;
  font-size: calc(18px + .4vw);
  color: $color-dark-gray;
  letter-spacing: 1px;
  padding-right: 0;
  min-width: 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.column-name .input {
  width: 450px;
}

.column-name__count {
  margin-left: $default-margin;
  margin-right: $default-margin;

  display: inline-block;
  width: 32px;
  min-width: 32px;
  height: 32px;
  line-height: 32px;
  vertical-align: middle;
  text-align: center;
  align-self: center;


  font-family: Heebo, sans-serif;
  font-size: 16px;
  font-weight: bold;

  color: $color-dark;
  background: lighten($color-stone-gray, 20%);
  border-radius: 16px;
}

.column-name .input::placeholder {
  text-transform: initial;
  color: $color-alternative-gray;
}