@import '../../constants/style';

$header__control-height: 66px;

.header__control {
  position: relative;

  background: $theme-color-secondary;
  padding: 0 $default-margin 0 0;
  height: $header__control-height;
  box-shadow: 0 4px 8px 0 transparentize($color-dark-gray, .85);

  display: flex;
}

.header__control > * {
  align-self: center;
}

.header__control-users {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.header__control-logo {
  flex-basis: 66px;
  margin-right: $default-margin;
}

@media #{$tablet} {
  .header__control {
    padding: 0 $large-margin 0 0;
  }

  .header__control-logo {
    display: block;
    justify-content: center;
    flex-basis: 150px;
    margin-right: $large-margin;
  }
}
