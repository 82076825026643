@import '../../constants/style';

.board__user-list {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
  align-self: center;
}

.board__user-list li {
  display: inline;
}

.board__user-list li:first-child {
  margin-left: 0;
}

.board__user-image {
  margin-top: 6px;
  margin-left: 6px;

  width: 32px;
  height: 32px;
  border-radius: 100%;
}

.board__user-image--faded {
  filter: brightness(75%);
}

.board__user-count {
  position: absolute;
  top: 0;
  left: 0;

  text-align: center;

  width: 32px;
  height: 32px;
  line-height: 32px;

  color: $color-white;
  font-size: 14px;
  font-weight: bold;
}

.board__user-ready-count {
  font-weight: bold;
  font-size: 12px;
  vertical-align: top;

  line-height: 20px;

  margin-top: 1px;
  margin-left: 6px;
}

.board__user--ready {
  font-size: 14px;
  line-height: 20px;
  color: $color-lightest-gray;

  margin-left: 3px;
  margin-right: 3px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
}
