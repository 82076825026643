@import '../../../../constants/style';

.header__logo-link {
  box-sizing: border-box;
  height: 100%;

  padding: $default-padding;

  outline: none;
  background: $color-mint;

  transition: background .2s ease-in-out;
}

.header__logo-link:focus,
.header__logo-link:hover {
  background: $color-dark;
}

.header__logo-image,
.header__logo-image-small {
  max-height: 100%;
}

.header__logo-image *,
.header__logo-image-small * {
   fill: $color-white;
}

.header__logo-image > svg,
.header__logo-image-small > svg {
  height: 36px;
  width: auto;
}

.header__logo-image {
  display: none;
  text-align: center;
  padding-right: 6px;
}

.header__logo-image-small {
  padding-left: 3px;
}

@media #{$tablet} {
  .header__logo-image {
    display: block;
  }

  .header__logo-image-small {
    display: none;
  }
}
